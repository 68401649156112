import React, { Component } from 'react';

import Map from '../map/map';
import Sidebar from '../sidebar/sidebar';
import Spinner from '../spinner/spinner';
import Overlay from '../overlay/overlay';

export default class Body extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      locations: null,
      filteredLocations: null,
      listView: false,
      showOverlay: false,
      selectedLocation: null,
      radius: "10",
      zipcode: "",
      selectedAges: {
        infant: true,
        toddler: true,
        twos: true,
        preschool: true,
        preKindergarten: true,
        afterSchool: true
      }
    };
  }

  componentWillMount() {
    require('./body.css');
  }

  async getLocationData() {
    try {
      const response = await fetch('https://kiddikollege.com/wp-json/locations/locations', {
        method: 'GET'
      });
      const locations = await response.json();
      this.setState({
        locations: locations.locations,
        filteredLocations: locations.locations,
        isLoading: false
      });
    } catch (error) {
      this.setState({
        locations: null,
        filteredLocations: null,
        isLoading: false
      });
    }
  }

  componentDidMount() {
    this.getLocationData();
  }

  render() {
    return (
      this.state.isLoading ?
      <Spinner /> :
      <div id="body">
        <Map
          locations={this.state.locations}
          filteredLocations={this.state.filteredLocations}
          toggleOverlay={this.toggleOverlay.bind(this)}
          selectedAges={this.state.selectedAges}
          radius={this.state.radius}
          zipcode={this.state.zipcode}
          setZipcode={this.setZipcode.bind(this)}
          updateLocations={this.updateLocations.bind(this)}
        />
        <Sidebar
          listView={this.state.listView}
          locations={this.state.locations}
          filteredLocations={this.state.filteredLocations}
          handleToggle={this.handleToggle.bind(this)}
          toggleOverlay={this.toggleOverlay.bind(this)}
          unmountApp={this.props.unmountApp}
          radius={this.state.radius}
          zipcode={this.state.zipcode}
          selectedAges={this.state.selectedAges}
          handleInput={this.handleInput.bind(this)}
          setZipcode={this.setZipcode.bind(this)}
        />
        <button id="activate-overlay" onClick={this.toggleOverlay.bind(this)} className="sro" data-location=""></button>
        {this.state.showOverlay ? <Overlay selectedLocation={this.state.selectedLocation} toggleOverlay={this.toggleOverlay.bind(this)} zipcode={this.state.zipcode}/> : null}
      </div>
    );
  }

  /**
   * State change handlers
   */
  toggleOverlay(event) {
    this.setState({
      showOverlay: !this.state.showOverlay,
      selectedLocation: event.target.getAttribute('data-location')
    });
  }

  handleToggle() {
    this.setState({ listView: !this.state.listView });
  }

  setZipcode(value) {
    this.setState({
      zipcode: value
    });
  }

  updateLocations(locations) {
    this.setState({
      filteredLocations: locations
    });
  }

  handleInput(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    if (target.type === 'checkbox') {
      this.setState( prevState => ({
        selectedAges:{
          ...prevState.selectedAges,
          [name]: value
        }
      }));
    } else {
      this.setState({
        [name]: value
      });
    }
  }
}