import React from 'react';
import './switch.css';

export default class Switch extends React.Component {
    render() {
        return (
            <div className="input-container">
                <input
                    checked={this.props.listView}
                    onChange={this.props.handleToggle}
                    className="sro"
                    id="react-switch-new"
                    type="checkbox"
                />
                <label
                    style={{ background: this.props.listView && '#6178ba' }}
                    className="react-switch-label"
                    htmlFor="react-switch-new"
                >
                    <span className="react-switch-button" />
                </label>
                <span className="switch-label">List View</span>
            </div>
        );
    }
}