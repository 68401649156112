import React from 'react';
import './sidebar.css';

import Switch from '../switch/switch';
import Search from '../search/search';
import Locations from '../locations/locations';

import { Scrollbars } from 'react-custom-scrollbars';

import closebtn from '../../assets/images/close.svg';

export default class Sidebar extends React.Component {
  render() {

    let sidebarView;
    if (this.props.listView) {
      sidebarView = <Locations locations={this.props.locations} filteredLocations={this.props.filteredLocations} toggleOverlay={this.props.toggleOverlay} />;
    } else {
      sidebarView = <Search radius={this.props.radius} zipcode={this.props.zipcode} selectedAges={this.props.selectedAges} handleInput={this.props.handleInput} />;
    }

    return (
      <div id="cn-sidebar" className={this.props.listView ? "extended" : null}>
        <button id="close" className="close" onClick={this.props.unmountApp}><img className="close-btn" src={closebtn} alt="Close App" title="Exit" /></button>
        <Switch listView={this.props.listView} handleToggle={this.props.handleToggle} />
        <div id="inner-sidebar">
          <Scrollbars
            autoHide={false}
            autoHideTimeout={600}
            hideTracksWhenNotNeeded={true}>
            {sidebarView}
          </Scrollbars>
        </div>
      </div>
    );
  }
}
