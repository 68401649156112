import React from 'react';
import './header.css';

import logo from '../../assets/images/kiddi-kollege-logo.png';

export default class Header extends React.Component {

  render() {
    let url = "https://kiddikollege.com";
    return (
      <header id="cn-header" role="banner">
        <div className="cn-container">
          <div className="cn-header__left">
            <a href={url}>
              <img src={logo} className="cn-logo" alt="Kiddi Kollege Logo" />
            </a>
            <span className="cn-heading">Kiddi Kollege Inc. <span className="cn-subheading">Est. 1957</span></span>
          </div>
          <div className="cn-header__right">
            <a href={url}>Home</a>
          </div>
        </div>
      </header>
    );
  }
}