import React from "react";
import "./form.css";

import Spinner from "../spinner/spinner";

export default class Layout extends React.Component {
  constructor(props) {
    super(props);

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      selectedLocation: this.props.selectedLocation,
      zipcode: this.props.zipcode,
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      contact: "",
      interests: "",
      message: "",
      newsletter: false,
      firstNameValid: "",
      lastNameValid: "",
      emailValid: "",
      phoneNumberValid: "",
      contactValid: "",
      interestsValid: "",
      formValid: false,
      formError: "",
      isSubmitting: false,
    };
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    const validStateName = target.name + "Valid";

    if (target.value === "") {
      this.setState({
        [validStateName]: false,
      });
    } else {
      this.setState({
        [validStateName]: true,
      });
    }

    this.setState({
      [name]: value,
    });
  }

  validateForm() {
    const formIsValid =
      this.state.firstNameValid &&
      this.state.lastNameValid &&
      this.state.emailValid &&
      this.state.phoneNumberValid &&
      this.state.contactValid &&
      this.state.interestsValid;

    this.setState({
      formValid: formIsValid,
    });

    return formIsValid;
  }

  async handleSubmit(event) {
    event.preventDefault();

    let validSubmit = this.validateForm();

    if (validSubmit) {
      try {
        // New URL on HTTPS /wp-json/gf/v2/forms/51/entries
        // Old url for testing /gravityformsapi/forms/51/submissions
        const body = {
          input_values: {
            input_1_3: this.state.firstName,
            input_1_6: this.state.lastName,
            input_2: this.state.email,
            input_3: this.state.phoneNumber,
            input_4: this.state.contact,
            input_5: this.state.interests,
            input_6: this.state.message,
            input_7: this.state.newsletter,
            input_8: this.state.selectedLocation,
            input_9: this.state.zipcode,
          },
        };

        const data = JSON.stringify(body);

        this.setState({
          isSubmitting: true,
        });

        const request = await fetch(
          "https://kiddikollege.com/gravityformsapi/forms/51/submissions",
          {
            method: "POST",
            body: data,
          }
        );
        const response = await request.json();

        if (response.response.is_valid === false) {
          this.setState({
            formError: "All fields are required. Please fill them all out.",
            isSubmitting: false,
          });
        } else {
          try {
            if (typeof ga === "function") {
              window.ga('gtm1.send', 'event', 'Centers Nearby', 'Form Submit', this.state.selectedLocation, 250);
           }
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              event: "centerNearbyFormSubmission",
              selectedLocation: this.state.selectedLocation,
              formTitle: 'Centers Nearby'
            });
          } catch (error) {
            console.log(error);
          }
          this.setState({
            formError:
              "Your form was successfully submitted! Thank you for contacting us. We will be in touch with you shortly.",
            isSubmitting: false,
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: "",
            contact: "",
            interests: "",
            message: "",
            newsletter: false,
            firstNameValid: "",
            lastNameValid: "",
            emailValid: "",
            phoneNumberValid: "",
            contactValid: "",
            interestsValid: "",
            formValid: false,
          });
        }
        document.getElementById("form").scrollIntoView();
        document.getElementById("form").scrollTop = 0;
      } catch (error) {
        this.setState({
          error: null,
          isSubmitting: false,
        });
      }
    } else {
      this.setState({
        formError: "Please fill out all required fields.",
      });
    }
  }

  render() {
    return (
      <div id="form">
        {this.state.isSubmitting ? <Spinner /> : null}

        <form
          action=""
          id="sb_form"
          autoComplete="on"
          className={this.state.isSubmitting ? "isSubmitting" : null}
        >
          <div className="form__header">
            <h2>
              Kiddi Kollege
              <br />
              {this.state.selectedLocation}
            </h2>
            <p>
              If you have questions about tuition and openings or want to
              schedule a tour,
              <br /> tell us about yourself and we will contact you shortly.
            </p>
          </div>

          {this.state.formError !== "" ? (
            <div id="gf-message">{this.state.formError}</div>
          ) : null}

          <div className="form-field half">
            <label htmlFor="input_1_3" className="sro">
              First Name
            </label>
            <input
              type="text"
              id="input_1_3"
              className={
                "gf-input" +
                (!this.state.firstNameValid && this.state.firstNameValid !== ""
                  ? " invalid"
                  : "")
              }
              placeholder="First Name"
              name="firstName"
              value={this.state.firstName}
              onChange={this.handleInputChange}
              onBlur={this.handleInputChange}
            />
          </div>

          <div className="form-field half">
            <label htmlFor="input_1_6" className="sro">
              Last Name
            </label>
            <input
              type="text"
              id="input_1_6"
              className={
                "gf-input" +
                (!this.state.lastNameValid && this.state.lastNameValid !== ""
                  ? " invalid"
                  : "")
              }
              placeholder="Last Name"
              name="lastName"
              value={this.state.lastName}
              onChange={this.handleInputChange}
              onBlur={this.handleInputChange}
            />
          </div>

          <div className="form-field half">
            <label htmlFor="input_2" className="sro">
              Email
            </label>
            <input
              type="email"
              id="input_2"
              className={
                "gf-input" +
                (!this.state.emailValid && this.state.emailValid !== ""
                  ? " invalid"
                  : "")
              }
              placeholder="Email"
              name="email"
              value={this.state.email}
              onChange={this.handleInputChange}
              onBlur={this.handleInputChange}
            />
          </div>

          <div className="form-field half">
            <label htmlFor="input_3" className="sro">
              Phone Number
            </label>
            <input
              type="phone"
              id="input_3"
              className={
                "gf-input" +
                (!this.state.phoneNumberValid &&
                this.state.phoneNumberValid !== ""
                  ? " invalid"
                  : "")
              }
              placeholder="Phone Number"
              name="phoneNumber"
              value={this.state.phoneNumber}
              onChange={this.handleInputChange}
              onBlur={this.handleInputChange}
            />
          </div>

          <div className="form-field half select">
            <label htmlFor="input_4" className="sro">
              Contact Preference
            </label>
            <select
              id="input_4"
              className={
                "gf-input" +
                (!this.state.contactValid && this.state.contactValid !== ""
                  ? " invalid"
                  : "")
              }
              name="contact"
              value={this.state.contact}
              onChange={this.handleInputChange}
              onBlur={this.handleInputChange}
            >
              <option value="" defaultValue>
                Contact Me By
              </option>
              <option value="phone">Phone</option>
              <option value="email">Email</option>
            </select>
          </div>

          <div className="form-field half select">
            <label htmlFor="input_5" className="sro">
              What Class Are You Interested In?
            </label>
            <select
              id="input_5"
              className={
                "gf-input" +
                (!this.state.interestsValid && this.state.interestsValid !== ""
                  ? " invalid"
                  : "")
              }
              name="interests"
              value={this.state.interests}
              onChange={this.handleInputChange}
              onBlur={this.handleInputChange}
            >
              <option value="">What Class Are You Interested In?</option>
              <option value="Infant">Infant</option>
              <option value="Toddler">Toddler</option>
              <option value="Two's">Two's</option>
              <option value="Preschool">Preschool</option>
              <option value="Pre-Kindergarten">Pre-Kindergarten</option>
              <option value="After School">After School</option>
            </select>
          </div>

          <div className="form-field">
            <label htmlFor="input_6" className="sro">
              Message
            </label>
            <textarea
              id="input_6"
              className="gf-input"
              placeholder="Message"
              value={this.state.message}
              name="message"
              onChange={this.handleInputChange}
            ></textarea>
          </div>

          <div className="form-field left">
            <label className="checkbox-container" htmlFor="input_7">
              Keep me informed about Kiddi Kollege programs
              <input
                className="sro"
                name="newsletter"
                type="checkbox"
                id="input_7"
                checked={this.state.newsletter}
                onChange={this.handleInputChange}
              />
              <span className="checkmark"></span>
            </label>
          </div>

          <div className="sro">
            <label htmlFor="input_8" className="sro">
              Selected Location
            </label>
            <select
              id="input_8"
              className="gf-input"
              name="selectedLocation"
              onChange={this.handleInputChange}
              value={this.state.selectedLocation}
            >
              <option value="Falcon Ridge">Falcon Ridge</option>
              <option value="Harold Street">Harold Street</option>
              <option value="Leawood">Leawood</option>
              <option value="Lindenwood Drive">Lindenwood Drive</option>
              <option value="Overland Park">Overland Park</option>
              <option value="Prairie Farms">Prairie Farms</option>
              <option value="Wellington Park">Wellington Park</option>
              <option value="Wyandotte Plaza">Wyandotte Plaza</option>
            </select>
          </div>

          <div className="form-field right">
            <button className="cn-btn" onClick={this.handleSubmit}>
              Send
            </button>
          </div>
        </form>
      </div>
    );
  }
}
