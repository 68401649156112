import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/app/app';

const target = document.getElementById('cn');
if (target) {
    document.getElementById('init-app').addEventListener('click', () => {
        ReactDOM.render(<App />, document.getElementById('cn'));
        document.getElementById('cn').style.display = 'block';
        try {
             if (typeof ga === "function") {
                 window.ga('gtm1.send', 'event', 'Centers Nearby', 'Opened App', window.location.href, 1);
             }
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              event: "centerNearbyFormSubmission",
              selectedLocation: this.state.selectedLocation,
              formTitle: 'Centers Nearby'
            });
        } catch (error) {
            console.log(error);
        }
    });
}
