import React from 'react';
import ReactDOM from 'react-dom';

import Header from '../header/header';
import Body from '../body/body';

export default class App extends React.Component {

  constructor(props) {
    super(props);
    this.unmountApp = this.unmountApp.bind(this);
  }

  componentWillMount() {
    require('./app.css');
  }

  unmountApp() {
    ReactDOM.unmountComponentAtNode(document.getElementById('cn'));
    document.getElementById('cn').style.display = 'none';
  }

  render() {
    return (
      <div id="app-wrapper">
        <Header />
        <Body unmountApp={this.unmountApp} showOverlay={this.showOverlay} openOverlay={this.openOverlay} />
      </div>
    );
  }
}