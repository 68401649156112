import React from 'react';
import './search.css';

import search from '../../assets/images/search.svg';

export default class Search extends React.Component {

    render() {
        return (
            <div className="scroll">
                <h2>Find Locations Near You</h2>
                <p>Enter your zipcode and check your preferences below to find locations best suited for your family's needs.</p>
                <div className="search">
                    <div className="form-field half-t">
                        <label htmlFor="zip-code" className="sro">Zip Code</label>
                        <input type="number" id="zip-code" name="zipcode" maxLength="5" placeholder="Enter Your Zip" value={this.props.zipcode} onChange={this.props.handleInput} />
                        <img src={search} alt="search icon" className="search-icon" />
                    </div>
                    <div className="form-field half-t select">
                        <label htmlFor="radius-select" className="sro">Radius (miles away)</label>
                        <select id="radius-select" name="radius" value={this.props.radius} onChange={this.props.handleInput}>
                            <option value="5">5 Miles Away</option>
                            <option value="10">10 Miles Away</option>
                            <option value="15">15 Miles Away</option>
                            <option value="20">20 Miles Away</option>
                            <option value="25">25 Miles Away</option>
                            <option value="30">30 Miles Away</option>
                        </select>
                    </div>
                </div>
                <h3>How Old Will Your Child Be?</h3>
                <div className="half">
                    <label className="checkbox-container" htmlFor="infant">Infant
                    <input type="checkbox" id="infant" name="infant" className="sro" onChange={this.props.handleInput} defaultChecked={this.props.selectedAges.infant} />
                        <span className="checkmark"></span>
                    </label>
                    <label className="checkbox-container" htmlFor="toddler">Toddler
                    <input type="checkbox" id="toddler" name="toddler" className="sro" onChange={this.props.handleInput} defaultChecked={this.props.selectedAges.toddler} />
                        <span className="checkmark"></span>
                    </label>
                    <label className="checkbox-container" htmlFor="twos">Two's
                    <input type="checkbox" id="twos" name="twos" className="sro" onChange={this.props.handleInput} defaultChecked={this.props.selectedAges.twos} />
                        <span className="checkmark"></span>
                    </label>
                </div>
                <div className="half bottom">
                    <label className="checkbox-container" htmlFor="preschool">Preschool
                    <input type="checkbox" id="preschool" name="preschool" className="sro" onChange={this.props.handleInput} defaultChecked={this.props.selectedAges.preschool} />
                        <span className="checkmark"></span>
                    </label>
                    <label className="checkbox-container" htmlFor="preKindergarten">Pre-Kindergarten
                    <input type="checkbox" id="preKindergarten" name="preKindergarten" className="sro" onChange={this.props.handleInput} defaultChecked={this.props.selectedAges.preKindergarten} />
                        <span className="checkmark"></span>
                    </label>
                    <label className="checkbox-container" htmlFor="afterSchool">After School
                    <input type="checkbox" id="afterSchool" name="afterSchool" className="sro" onChange={this.props.handleInput} defaultChecked={this.props.selectedAges.afterSchool} />
                        <span className="checkmark"></span>
                    </label>
                </div>
            </div>
        );
    }
}
