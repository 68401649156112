import React from 'react';
import './overlay.css';

import Form from '../form/form';

import closebtn from '../../assets/images/close.svg';

export default class Overlay extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
  }

  componentDidMount() {
    this.timeoutId = setTimeout(function() {
      this.setState({
        show: true
      });
    }.bind(this), 250);
  }

  componentWillUnmount() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }

  render() {
    return (
      <div id="overlay" className={this.state.show ? 'show' : null}>
        <button id="close-overlay" className="close" onClick={this.props.toggleOverlay}><img className="close-btn" src={closebtn} alt="Close Overlay" title="Exit Overlay" /></button>
        <Form selectedLocation={this.props.selectedLocation} zipcode={this.props.zipcode}/>
      </div>
    );
  }
}
