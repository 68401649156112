import React from 'react';
import './locations.css';

export default class Locations extends React.Component {

    locationsList() {
        let locations;
        if (this.props.filteredLocations.length === 0) {
            locations = (<div className="wrapper__location"><div><h2>Sorry, there are no locations found within the search criteria.</h2><p>Please visit our <a href="https://kiddikollege.com/locations/">locations page</a> to see our service area.</p></div></div>);
        } else {
            locations = this.props.filteredLocations.map(function (item, index) {
                return (
                    <div className="wrapper__location" key={index}>
                        <div className="location__first">
                            <img src={item.image} alt={item.title} title={item.title} />
                            <div className="button__container">
                                <a href={"tel:" + item.phone} title="Call Us" className="cn-btn white">{item.phone}</a>
                                <button title="Check Our Openings" className="cn-btn" onClick={this.props.toggleOverlay} data-location={item.title}>Openings</button>
                            </div>
                        </div>
                        <div className="location__last">
                            <h2>{item.title}</h2>
                            <p>Distance Away: <strong>{item.distance} miles</strong></p>
                            <p>{item.street}<br />{item.city}, {item.statezip}</p>
                            <p><strong>Ages:</strong> {item.ages}<br /><strong>Open:</strong> {item.open}</p>
                        </div>
                    </div>
                )
            }, this);
        }
        return locations;
    }

    render() {

        let locations = this.locationsList();

        return (
            <div className="scroll">
                {locations}
            </div>
        );
    }
}
