import React, { Component } from 'react';
import './map.css';

import markerIcon from '../../assets/images/pin.png';

export default class Map extends Component {

  constructor(props) {
    super(props);

    this.state = {
      googleMap: null,
      geocoder: null,
      bounds: null,
      currentLocation: {
        lat: 38.9726605,
        lng: -94.70168799999999
      },
      radiusCircle: null,
      mapMarkers: [],
      infoWindows: [],
      infoWindowOpen: false,
    };
  }

  setStateAsync(state) {
		return new Promise((resolve) => {
			this.setState(state, resolve);
		});
	}

  createGoogleMap = () => {
    const map = new window.google.maps.Map(
      document.getElementById('map'), {
        zoom: 11,
        scrollwheel: false,
        mapTypeId: "roadmap",
        mapTypeControl: true,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        icon: {
          url: markerIcon
        },
        zoomControlOptions: {
          position: window.google.maps.ControlPosition.LEFT_CENTER
        }
      }
    );
    return map;
  }

  createGeocoder() {
    const geocoder = new window.google.maps.Geocoder();
    return geocoder;
  }

  createMarker = (marker, position) => {
    let mapMarkers = this.state.mapMarkers;
    let infoWindowsState = this.state.infoWindows;
    const locationMarker = new window.google.maps.Marker({
      position: position,
      map: this.state.googleMap,
      animation: window.google.maps.Animation.DROP,
      icon: {
        url: markerIcon
      }
    });

    const infoWindow = new window.google.maps.InfoWindow({
      content: `<div id="content">
        <h2>${marker.title}</h2>
        <div class="wrapper__infobox">
          <div class="infobox__inner">
            <img src="${marker.image}" alt="${marker.title}" />
          </div>
          <div class="infobox__inner">
            <p>Distance Away: <strong>${marker.distance} miles</strong><br />
              <strong>Ages:</strong> ${marker.ages}<br />
              <strong>Open:</strong> ${marker.open}
            </p>
            <a href="${marker.link}" title="Learn more about this location" class="cn-btn yellow">More Info</a>
          </div>
          <div class="infobox__inner">
            <p>${marker.street}<br />
              ${marker.city}, ${marker.statezip}<br />
              <a href={"tel:${marker.phone}" title="Call Us" class="extra">${marker.phone}</a>
            </p>
            <button title="Check Our Openings" class="cn-btn"
            onClick="(function click(event){
              var el = document.getElementById('activate-overlay');
              el.setAttribute('data-location',event.getAttribute('data-location'));
              var evt = new MouseEvent('click', {
                bubbles: true,
                cancelable: true,
                view: window
              });
              el.dispatchEvent(evt);
            })(this)"
            data-location="${marker.title}">Openings</button>
          </div>
        </div>
      </div>`
    });

    mapMarkers.push(locationMarker);
    infoWindowsState.push(infoWindow);

    this.setState({
      mapMarkers: mapMarkers,
      infoWindows: infoWindowsState
    });

    locationMarker.addListener('click', () => {
      if (this.state.infoWindowOpen) {
        for(let y = 0; y < this.state.infoWindows.length; y++) {
          this.state.infoWindows[y].close(this.state.googleMap, locationMarker);
        }
      }
      this.setState({
        infoWindowOpen: true
      }, () => {
        infoWindow.open(this.state.googleMap, locationMarker);
      });
    });
  }

  async setLocationFromZip() {
    let address = this.props.zipcode;
    let lat = this.state.currentLocation.lat;
    let lng = this.state.currentLocation.lng;
    this.state.geocoder.geocode({ 'address': address },
    async (results, status) => {
      if (status === window.google.maps.GeocoderStatus.OK) {
        lat = results[0].geometry.location.lat();
        lng = results[0].geometry.location.lng();
      }
      await this.setStateAsync({
        currentLocation: {
          lat: lat,
          lng: lng
        }
      });
      await this.placeMarkers();
    });
  }

  getUsersLocation() {
    let currentLocation = this.state.currentLocation;
    if (navigator && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (pos) => {
          const coords = pos.coords;
          currentLocation = {
            lat: coords.latitude,
            lng: coords.longitude
          };
          await this.setStateAsync({
            currentLocation: currentLocation
          })
          this.setZipcodeFromLatLng();
        },
        (error) => {
          console.log(error);
        }
      );
    } else {
      return currentLocation;
    }
  }

  getCurrentLatLng() {
    return new window.google.maps.LatLng(this.state.currentLocation.lat, this.state.currentLocation.lng);
  }

  setZipcodeFromLatLng() {
    this.state.geocoder.geocode(
      { 'latLng': this.getCurrentLatLng() },
      (results, status) => {
        if (status === window.google.maps.GeocoderStatus.OK) {
          if (results[0]) {
            for (let j = 0; j < results[0].address_components.length; j++) {
              if (results[0].address_components[j].types[0] === 'postal_code') {
                this.props.setZipcode(results[0].address_components[j].short_name);
              }
            }
          }
        } else {
          this.props.setZipcode("66212");
        }
      }
    );
  }

  centerMap() {
    if (this.props.filteredLocations.length === 0) {
      this.state.googleMap.setCenter(this.getCurrentLatLng());
      this.state.googleMap.setZoom(11);
    }
    if (this.props.filteredLocations.length === 1) {
      var position = new window.google.maps.LatLng(parseFloat(this.props.filteredLocations[0].lat), parseFloat(this.props.filteredLocations[0].lng));
      this.state.googleMap.setCenter(position);
      this.state.googleMap.setZoom(16);
    }
    if (this.state.bounds !== null && this.props.filteredLocations.length > 1) {
      this.state.googleMap.setCenter(this.state.bounds.getCenter());
    }
  }

  fitBounds() {
    this.setState({
      bounds: new window.google.maps.LatLngBounds()
    }, () => {
        if (this.props.filteredLocations.length > 1) {
          for (let i = 0; i < this.props.filteredLocations.length; i++) {
            var position = new window.google.maps.LatLng(parseFloat(this.props.filteredLocations[i].lat), parseFloat(this.props.filteredLocations[i].lng));
            this.state.bounds.extend(position);
          }
          this.state.googleMap.fitBounds(this.state.bounds);
        }
        this.centerMap();
    });
  }

  async placeMarkers() {
    if (this.state.mapMarkers.length > 0) {
      for (let l = 0; l < this.state.mapMarkers.length; l++) {
        this.state.mapMarkers[l].setMap(null);
      }
    }
    this.setState({
        mapMarkers: [],
        infoWindows: [],
        infoWindowOpen: false,
      }, () => {
        let filteredLocations = [];
        for (let i = 0; i < this.props.locations.length; i++) {
          let thisLocation = this.props.locations[i];
          let distance = this.isInRadius(thisLocation);
          let hasCorrectAges = this.isInAgeRange(thisLocation);
          if (distance !== false && hasCorrectAges ) {
            let position = new window.google.maps.LatLng(parseFloat(this.props.locations[i].lat), parseFloat(this.props.locations[i].lng));
            thisLocation.distance = distance;
            this.createMarker(thisLocation, position);
            filteredLocations.push(thisLocation);
            this.state.bounds.extend(position);
          }
        }
        this.props.updateLocations(filteredLocations);
        this.mapResized();
      }
    );
  }

  isInRadius(location) {
    let center = this.getCurrentLatLng();
    let distance = 0;
    var markerPosition = new window.google.maps.LatLng(parseFloat(location.lat), parseFloat(location.lng));
    // Gets distance of marker from center in miles. computeDistanceBetween returns meters and we divide to get miles and round to nearest mile.
    distance = Math.round(
      window.google.maps.geometry.spherical.computeDistanceBetween(center, markerPosition) / 1609.344
    );
    if (distance < this.props.radius) {
      return distance;
    }
    return false;
  }

  isInAgeRange(location) {
    let locationAges = location.supportedAges;
    let selectedAges = this.props.selectedAges;
    for (let x = 0; x < locationAges.length; x++) {
      if (selectedAges[locationAges[x]] === true) {
        return true;
      }
    }
    return false;
  }

  mapLoaded = async () => {
    this.getUsersLocation();
    await this.setStateAsync({
      googleMap: this.createGoogleMap(),
      geocoder: this.createGeocoder(),
      bounds: new window.google.maps.LatLngBounds(),
    });
    this.setZipcodeFromLatLng();
    this.centerMap();
    this.placeMarkers();
    this.state.googleMap.fitBounds(this.state.bounds);
    window.google.maps.event.addDomListener(window, "resize", this.mapResized);
  }

  mapResized = () => {
    window.google.maps.event.trigger(this.state.googleMap, "resize");
    this.fitBounds();
  }

  componentDidMount() {
    const googleMapScript = document.createElement('script');
    googleMapScript.setAttribute("id", "googleMapScript");
    googleMapScript.src = 'https://maps.googleapis.com/maps/api/js?libraries=places,geometry&key=AIzaSyDIuQ4aUsHGn79sxc8kZWZURdtKtnal1TI';
    window.document.body.appendChild(googleMapScript);
    document.getElementById('googleMapScript').addEventListener('load', this.mapLoaded);
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.zipcode !== this.props.zipcode) {
      if (this.props.zipcode.length === 5) {
        await this.setLocationFromZip();
      }
    }
    if (prevProps.radius !== this.props.radius || prevProps.selectedAges !== this.props.selectedAges) {
      this.placeMarkers();
    }
  }

  componentWillUnmount() {
    let el = document.getElementById('googleMapScript');
    el.removeEventListener('load', this.mapLoaded);
    el.parentNode.removeChild(el);
    window.google.maps.event.clearListeners(this.state.googleMap, 'resize');
  }

  render() {
    return (
      <div
        id="map"
        style={{width:'100%',height:'100%'}}
      />
    );
  }
}
